import { Component, DestroyRef, inject, Input } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';

import { FeatureFlagNames } from '@app/core/feature-flag/shared/feature-flag.type';
import { LaunchDarklyService } from '@app/core/launch-darkly/launchdarkly.service';
import { MessagingService } from '@app/modules/messaging/shared/messaging.service';
import { MessagingActions } from '@app/modules/messaging/store/messaging.actions';

import { getPharmacyDisplay } from '../../../../modules/shared-rx/utils/pharmacy-utils';
import { cartApprovals, cartDenials } from '../../shared/renewal-utils';
import { DenialReason, Renewal } from '../../shared/renewals.type';

@Component({
  selector: 'omg-completed-renewals-approved-and-denied',
  templateUrl: './completed-renewals-approved-and-denied.component.html',
})
export class CompletedRenewalsApprovedAndDeniedComponent {
  @Input() renewals: Renewal[] = [];

  private destroyRef = inject(DestroyRef);

  constructor(
    private launchDarklyService: LaunchDarklyService,
    private messagingActions: MessagingActions,
    private messagingService: MessagingService,
  ) {}
  get approvedRenewals() {
    return cartApprovals(this.renewals);
  }

  get deniedRenewals() {
    return cartDenials(this.renewals);
  }

  get pharmacyPhrase() {
    return getPharmacyDisplay(this.renewals[0]?.pharmacy);
  }

  get countOfRenewalsPhrase() {
    return `${this.renewals.length} renewal${
      this.renewals.length > 1 ? 's' : ''
    } processed and sent to`;
  }

  get draftRenewalMessageEnabled$() {
    return this.launchDarklyService.variation$(
      FeatureFlagNames.draftRenewalCheckoutDenialMessage,
      false,
    );
  }

  openMessage() {
    const topic = 'Regarding Your Prescription Request';
    const content = {
      topic,
      content: '',
    };
    const reasons = this.deniedRenewals.map(renewal => renewal.denialReason);
    const sameReasons = reasons.every(
      reason => reason.code === reasons[0].code,
    );
    const specificTopic = this.specificTopic(reasons[0]);
    if (sameReasons && specificTopic) {
      content.topic = `${topic}: ${specificTopic}`;
    }

    this.messagingService
      .createPost(content)
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe(post => this.messagingActions.setCurrentPost(post.id));
  }

  private specificTopic = (reason: DenialReason) => {
    switch (reason.code) {
      case 'AD':
        return 'Refills Available';
      case 'AM':
      case 'AF':
        return 'Follow-Up Needed';
      default:
        return;
    }
  };
}
