import { NgModule } from '@angular/core';
import {
  ApolloClientOptions,
  ApolloLink,
  InMemoryCache,
} from '@apollo/client/core';
import { AuthService } from '@auth0/auth0-angular';
import {
  APOLLO_NAMED_OPTIONS,
  ApolloModule,
  NamedOptions,
} from 'apollo-angular';
import { HttpLink } from 'apollo-angular/http';
import { firstValueFrom } from 'rxjs';

import { ConfigService } from '@app/core/config';
import { createAuthLink } from 'aws-appsync-auth-link';
import { createSubscriptionHandshakeLink } from 'aws-appsync-subscription-link';

function onelife(
  configService: ConfigService,
  httpLink: HttpLink,
): ApolloClientOptions<any> {
  const uri = `${configService.environment.api.baseUrl}/graphql`;
  const link = httpLink.create({ uri });
  return {
    link,
    cache: new InMemoryCache({
      typePolicies: {
        pdmpLastViewed: {
          keyFields: ['requestedLocation', 'patientId'],
        },
      },
    }),
  };
}

function appsync(
  configService: ConfigService,
  authService: AuthService,
): ApolloClientOptions<any> {
  const { apiUrl, region } = configService.environment.appSync;
  const link = ApolloLink.from([
    createAuthLink({
      url: apiUrl,
      region,
      auth: {
        type: 'OPENID_CONNECT',
        jwtToken: () => firstValueFrom(authService.getAccessTokenSilently()),
      },
    }),
    createSubscriptionHandshakeLink({
      url: apiUrl,
      region,
      auth: {
        type: 'OPENID_CONNECT',
        jwtToken: () => firstValueFrom(authService.getAccessTokenSilently()),
      },
    }),
  ]);

  return {
    link,
    cache: new InMemoryCache(),
  };
}

function factory(
  httpLink: HttpLink,
  configService: ConfigService,
  authService: AuthService,
): NamedOptions {
  return {
    onelife: onelife(configService, httpLink),
    appsync: appsync(configService, authService),
  };
}

@NgModule({
  imports: [ApolloModule],
  providers: [
    {
      provide: APOLLO_NAMED_OPTIONS,
      useFactory: factory,
      deps: [HttpLink, ConfigService, AuthService],
    },
  ],
})
export class GraphQLModule {}
