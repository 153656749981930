// @ts-strict-ignore
import { Injectable } from '@angular/core';
import { map } from 'rxjs/operators';

import { GetTodoMessageReassignmentQueryService } from '@app/modules/messaging/shared/todo-message-reassignment-query.onelife.generated';
import { mapResponseToTodoReassignment } from '@app/modules/messaging/shared/todo-reassignment-mappers';

export interface TodoReassignment {
  id: string;
  newAssigneeName: string;
  originalAssigneeName: string;
  labels: string[];
  feedbackExists: boolean;
  feedbackHasNewAssignee: boolean;
}

@Injectable()
export class TodoReassignmentService {
  constructor(
    private todoMessageReassignmentQueryService: GetTodoMessageReassignmentQueryService,
  ) {}

  getTodoMessageReassignment(postId: number, patientId: number) {
    return this.todoMessageReassignmentQueryService
      .fetch({
        postId: postId.toString(),
        patientId: patientId.toString(),
      })
      .pipe(map(response => mapResponseToTodoReassignment(response.data)));
  }
}
