/* eslint-disable */
/*
====================================================
THIS IS AN AUTOGENERATED FILE
DO NOT EDIT. 
Update with `npm run graphql-codegen`
====================================================
*/
import * as Types from '../../../../graphql/onelife.type';

import { gql } from 'apollo-angular';
import { Injectable } from '@angular/core';
import { Apollo, Query, Mutation, Subscription } from 'apollo-angular';
export type DraftInfoFragment = { __typename?: 'SmartDraft', id: string, state: Types.SmartDraftState, text?: any | null, replyInstructions: any, feedback?: Types.SmartDraftFeedback | null };

export type GetSmartDraftQueryVariables = Types.Exact<{
  id: Types.Scalars['ID']['input'];
}>;


export type GetSmartDraftQuery = { __typename?: 'Query', smartDraft: { __typename?: 'SmartDraft', id: string, state: Types.SmartDraftState, text?: any | null, replyInstructions: any, feedback?: Types.SmartDraftFeedback | null } };

export type CreateSmartDraftMutationVariables = Types.Exact<{
  input: Types.CreateSmartDraftInput;
}>;


export type CreateSmartDraftMutation = { __typename?: 'Mutation', createSmartDraft?: { __typename?: 'CreateSmartDraftPayload', success: boolean, mutationErrors?: Array<{ __typename?: 'MutationError', messages: Array<string> }> | null, smartDraft?: { __typename?: 'SmartDraft', id: string, state: Types.SmartDraftState, text?: any | null, replyInstructions: any, feedback?: Types.SmartDraftFeedback | null } | null } | null };

export type SaveSmartDraftFeedbackMutationVariables = Types.Exact<{
  input: Types.SaveSmartDraftFeedbackInput;
}>;


export type SaveSmartDraftFeedbackMutation = { __typename?: 'Mutation', saveSmartDraftFeedback?: { __typename?: 'SaveSmartDraftFeedbackPayload', success: boolean, smartDraft?: { __typename?: 'SmartDraft', id: string, state: Types.SmartDraftState, text?: any | null, replyInstructions: any, feedback?: Types.SmartDraftFeedback | null } | null, mutationErrors?: Array<{ __typename?: 'MutationError', messages: Array<string> }> | null } | null };

export type SuppressSmartDraftLimitationsWarningMutationVariables = Types.Exact<{
  input: Types.SaveUserSmartDraftSettingsInput;
}>;


export type SuppressSmartDraftLimitationsWarningMutation = { __typename?: 'Mutation', saveUserSmartDraftSettings?: { __typename?: 'SaveUserSmartDraftSettingsPayload', success: boolean, mutationErrors?: Array<{ __typename?: 'MutationError', messages: Array<string> }> | null } | null };

export type SmartDraftSettingsInfoFragment = { __typename?: 'InternalUser', id: string, doNotShowSmartDraftLimitationsAgain: boolean };

export type GetProfileQueryVariables = Types.Exact<{ [key: string]: never; }>;


export type GetProfileQuery = { __typename?: 'Query', profile?: { __typename?: 'InternalUser', id: string, doNotShowSmartDraftLimitationsAgain: boolean } | null };

export const DraftInfoFragmentDoc = gql`
    fragment DraftInfo on SmartDraft {
  id
  state
  text
  replyInstructions
  feedback
}
    `;
export const SmartDraftSettingsInfoFragmentDoc = gql`
    fragment SmartDraftSettingsInfo on InternalUser {
  id
  doNotShowSmartDraftLimitationsAgain
}
    `;
export const GetSmartDraftDocument = gql`
    query GetSmartDraft($id: ID!) {
  smartDraft(id: $id) {
    ...DraftInfo
  }
}
    ${DraftInfoFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class GetSmartDraftQueryService extends Query<GetSmartDraftQuery, GetSmartDraftQueryVariables> {
    document = GetSmartDraftDocument;
    client = 'onelife';
    constructor(apollo: Apollo) {
      super(apollo);
    }
  }
export const CreateSmartDraftDocument = gql`
    mutation CreateSmartDraft($input: CreateSmartDraftInput!) {
  createSmartDraft(input: $input) {
    success
    mutationErrors {
      messages
    }
    smartDraft {
      ...DraftInfo
    }
  }
}
    ${DraftInfoFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class CreateSmartDraftMutationService extends Mutation<CreateSmartDraftMutation, CreateSmartDraftMutationVariables> {
    document = CreateSmartDraftDocument;
    client = 'onelife';
    constructor(apollo: Apollo) {
      super(apollo);
    }
  }
export const SaveSmartDraftFeedbackDocument = gql`
    mutation SaveSmartDraftFeedback($input: SaveSmartDraftFeedbackInput!) {
  saveSmartDraftFeedback(input: $input) {
    success
    smartDraft {
      ...DraftInfo
    }
    mutationErrors {
      messages
    }
  }
}
    ${DraftInfoFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class SaveSmartDraftFeedbackMutationService extends Mutation<SaveSmartDraftFeedbackMutation, SaveSmartDraftFeedbackMutationVariables> {
    document = SaveSmartDraftFeedbackDocument;
    client = 'onelife';
    constructor(apollo: Apollo) {
      super(apollo);
    }
  }
export const SuppressSmartDraftLimitationsWarningDocument = gql`
    mutation SuppressSmartDraftLimitationsWarning($input: SaveUserSmartDraftSettingsInput!) {
  saveUserSmartDraftSettings(input: $input) {
    success
    mutationErrors {
      messages
    }
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class SuppressSmartDraftLimitationsWarningMutationService extends Mutation<SuppressSmartDraftLimitationsWarningMutation, SuppressSmartDraftLimitationsWarningMutationVariables> {
    document = SuppressSmartDraftLimitationsWarningDocument;
    client = 'onelife';
    constructor(apollo: Apollo) {
      super(apollo);
    }
  }
export const GetProfileDocument = gql`
    query GetProfile {
  profile {
    ...SmartDraftSettingsInfo
  }
}
    ${SmartDraftSettingsInfoFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class GetProfileQueryService extends Query<GetProfileQuery, GetProfileQueryVariables> {
    document = GetProfileDocument;
    client = 'onelife';
    constructor(apollo: Apollo) {
      super(apollo);
    }
  }