/* eslint-disable */
/*
====================================================
THIS IS AN AUTOGENERATED FILE
DO NOT EDIT. 
Update with `npm run graphql-codegen`
====================================================
*/
import * as Types from '../../../../../graphql/onelife.type';

import { gql } from 'apollo-angular';
import { Injectable } from '@angular/core';
import { Apollo, Query, Mutation, Subscription } from 'apollo-angular';
export type SummaryFragment = { __typename?: 'TimelinePost', id: string, summary?: string | null };

export type GetThreadSummaryQueryVariables = Types.Exact<{
  id: Types.Scalars['ID']['input'];
  ids?: Types.InputMaybe<Array<Types.Scalars['ID']['input']> | Types.Scalars['ID']['input']>;
}>;


export type GetThreadSummaryQuery = { __typename?: 'Query', patient?: { __typename?: 'Patient', posts: { __typename?: 'TimelinePostConnection', nodes: Array<{ __typename?: 'TimelinePost', id: string, summary?: string | null }> } } | null };

export const SummaryFragmentDoc = gql`
    fragment Summary on TimelinePost {
  id
  summary
}
    `;
export const GetThreadSummaryDocument = gql`
    query GetThreadSummary($id: ID!, $ids: [ID!]) {
  patient(id: $id) {
    posts(ids: $ids) {
      nodes {
        ...Summary
      }
    }
  }
}
    ${SummaryFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class GetThreadSummaryQueryService extends Query<GetThreadSummaryQuery, GetThreadSummaryQueryVariables> {
    document = GetThreadSummaryDocument;
    client = 'onelife';
    constructor(apollo: Apollo) {
      super(apollo);
    }
  }