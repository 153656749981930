// @ts-strict-ignore
import { Component, DestroyRef, inject, Input, OnInit } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { UntypedFormControl } from '@angular/forms';

import { buildCheckoutMessages } from '@app/modules/rx-cart/shared/rx-cart-utils';

import { CheckoutRenewal } from '../../shared/renewals.type';

@Component({
  selector: 'omg-renewal-checkout-list-item',
  templateUrl: './renewal-checkout-list-item.component.html',
  styleUrls: ['./renewal-checkout-list-item.component.css'],
})
export class RenewalCheckoutListItemComponent implements OnInit {
  @Input() renewal: CheckoutRenewal;
  handwrittenConfirmed = new UntypedFormControl();
  checkoutMessages = buildCheckoutMessages();

  private destroyRef = inject(DestroyRef);

  ngOnInit() {
    this.handwrittenConfirmed.valueChanges
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe(checked => {
        this.renewal.handwrittenConfirmed = checked;
      });
  }
}
