/* eslint-disable */
/*
====================================================
THIS IS AN AUTOGENERATED FILE
DO NOT EDIT. 
Update with `npm run graphql-codegen`
====================================================
*/
import * as Types from '../../../../graphql/onelife.type';

import { gql } from 'apollo-angular';
import { Injectable } from '@angular/core';
import { Apollo, Query, Mutation, Subscription } from 'apollo-angular';
export type PatientPostsFragment = { __typename?: 'Patient', posts: { __typename?: 'TimelinePostConnection', nodes: Array<{ __typename?: 'TimelinePost', reassignment?: { __typename?: 'TodoMessageReassignment', id: string, labels: Array<string>, newAssignee?: { __typename?: 'InternalUser', displayName: string } | { __typename?: 'RoleType', displayName: string } | null, originalAssignee?: { __typename?: 'InternalUser', displayName: string } | { __typename?: 'RoleType', displayName: string } | null, feedback?: { __typename?: 'TodoMessageReassignmentFeedback', id: string, newAssignee?: { __typename?: 'InternalUser', displayName: string } | { __typename?: 'RoleType', displayName: string } | null } | null } | null }> } };

export type ReassignmentInfoFragment = { __typename?: 'TimelinePost', reassignment?: { __typename?: 'TodoMessageReassignment', id: string, labels: Array<string>, newAssignee?: { __typename?: 'InternalUser', displayName: string } | { __typename?: 'RoleType', displayName: string } | null, originalAssignee?: { __typename?: 'InternalUser', displayName: string } | { __typename?: 'RoleType', displayName: string } | null, feedback?: { __typename?: 'TodoMessageReassignmentFeedback', id: string, newAssignee?: { __typename?: 'InternalUser', displayName: string } | { __typename?: 'RoleType', displayName: string } | null } | null } | null };

export type GetTodoMessageReassignmentQueryVariables = Types.Exact<{
  postId: Types.Scalars['ID']['input'];
  patientId: Types.Scalars['ID']['input'];
}>;


export type GetTodoMessageReassignmentQuery = { __typename?: 'Query', patients: Array<{ __typename?: 'Patient', posts: { __typename?: 'TimelinePostConnection', nodes: Array<{ __typename?: 'TimelinePost', reassignment?: { __typename?: 'TodoMessageReassignment', id: string, labels: Array<string>, newAssignee?: { __typename?: 'InternalUser', displayName: string } | { __typename?: 'RoleType', displayName: string } | null, originalAssignee?: { __typename?: 'InternalUser', displayName: string } | { __typename?: 'RoleType', displayName: string } | null, feedback?: { __typename?: 'TodoMessageReassignmentFeedback', id: string, newAssignee?: { __typename?: 'InternalUser', displayName: string } | { __typename?: 'RoleType', displayName: string } | null } | null } | null }> } }> };

export const ReassignmentInfoFragmentDoc = gql`
    fragment ReassignmentInfo on TimelinePost {
  reassignment: todoMessageReassignment {
    id
    newAssignee {
      displayName
    }
    originalAssignee {
      displayName
    }
    labels
    feedback {
      id
      newAssignee {
        displayName
      }
    }
  }
}
    `;
export const PatientPostsFragmentDoc = gql`
    fragment PatientPosts on Patient {
  posts(ids: [$postId]) {
    nodes {
      ...ReassignmentInfo
    }
  }
}
    ${ReassignmentInfoFragmentDoc}`;
export const GetTodoMessageReassignmentDocument = gql`
    query GetTodoMessageReassignment($postId: ID!, $patientId: ID!) {
  patients(ids: [$patientId]) {
    ...PatientPosts
  }
}
    ${PatientPostsFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class GetTodoMessageReassignmentQueryService extends Query<GetTodoMessageReassignmentQuery, GetTodoMessageReassignmentQueryVariables> {
    document = GetTodoMessageReassignmentDocument;
    client = 'onelife';
    constructor(apollo: Apollo) {
      super(apollo);
    }
  }