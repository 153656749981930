// @ts-strict-ignore
import {
  Component,
  computed,
  EventEmitter,
  inject,
  Input,
  Output,
  Signal,
} from '@angular/core';
import { toSignal } from '@angular/core/rxjs-interop';
import { UntypedFormGroup } from '@angular/forms';

import { AnalyticsService } from '@app/core/analytics/analytics.service';
import { AnalyticsEvent } from '@app/core/analytics/analytics.type';
import { DefaultAnalyticsProps } from '@app/core/analytics/default-analytics-props';
import { FeatureFlagNames } from '@app/core/feature-flag/shared/feature-flag.type';
import { LaunchDarklyService } from '@app/core/launch-darkly/launchdarkly.service';
import { PatientChartActions } from '@app/features/patient-chart/store/patient-chart.actions';
import { PatientChartSelectors } from '@app/features/patient-chart/store/patient-chart.selectors';
import { Todo } from '@app/modules/todo/shared/todo.type';
import { CollapseProviderDirective } from '@app/shared/components/collapse';

import { Note } from '../../shared/note.type';

@Component({
  selector: 'omg-note-header',
  templateUrl: './note-header.component.html',
  styleUrls: ['./note-header.component.scss'],
})
export class NoteHeaderComponent {
  @Input() editable: boolean;
  @Input() note: Note;
  @Input() todo: Todo;
  @Input() form: UntypedFormGroup;
  @Input() commentsCollapseRef: CollapseProviderDirective;

  @Output() closeNoteHeader = new EventEmitter<void>();

  expandEnabled: boolean;
  patientChartSelectors = inject(PatientChartSelectors);
  isExpanded = toSignal(this.patientChartSelectors.workspaceExpanded);
  toolTipText: Signal<string> = computed(() =>
    this.isExpanded() ? 'Collapse' : 'Expand',
  );
  toolTipIcon: Signal<string> = computed(() =>
    this.isExpanded() ? 'clickable fa fa-compress' : 'clickable fa fa-expand',
  );

  constructor(
    private analytics: AnalyticsService,
    private patientChartActions: PatientChartActions,
    private launchDarklyService: LaunchDarklyService,
  ) {}

  ngOnInit() {
    this.expandEnabled = this.launchDarklyService.variation(
      FeatureFlagNames.expandDocumentsFromWorkspace,
      false,
    );
  }

  onClose() {
    this.patientChartActions.expandWorkspace(false);
    this.closeNoteHeader.emit();
  }

  get noteHasDocuments(): boolean {
    return !!this.note.documents && this.note.documents.length > 0;
  }

  setExpanded() {
    this.patientChartActions.expandWorkspace(!this.isExpanded());
  }

  trackTitleUnselected(): void {
    this.analytics.track(AnalyticsEvent.FieldUnselected, {
      ...DefaultAnalyticsProps,
      component: this.note.noteType.name,
      subcomponent: 'Title',
      noteId: this.note.id,
    });
  }

  trackTitleSelected(): void {
    this.analytics.track(AnalyticsEvent.FieldSelected, {
      ...DefaultAnalyticsProps,
      component: this.note.noteType.name,
      subcomponent: 'Title',
      noteId: this.note.id,
    });
  }

  trackCommentClicked(): void {
    this.analytics.track(AnalyticsEvent.AddCommentClicked, {
      ...DefaultAnalyticsProps,
      component: this.note.noteType.name,
      subcomponent: 'Add Comment Button',
      noteId: this.note.id,
      method: this.commentsCollapseRef.expanded ? 'Expand' : 'Collapse',
    });
  }
}
