// @ts-strict-ignore
import {
  ChangeDetectionStrategy,
  Component,
  Input,
  OnChanges,
} from '@angular/core';
import { SelectItem } from 'primeng/api';

import { Summary } from '@app/features/summaries/shared/summaries.type';
import { Note } from '@app/modules/note/shared/note.type';
import { Todo } from '@app/modules/todo/shared/todo.type';
import { DateFormats, formatDate } from '@app/utils';

import { DropdownItem } from '../dropdown/dropdown-item';

// Potentially can be other notes types than Summary
type HistoryNote = Summary | Note;

@Component({
  selector: 'omg-note-history',
  templateUrl: './note-history.component.html',
  styleUrls: ['./note-history.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class NoteHistoryComponent implements OnChanges {
  @Input() note: HistoryNote;
  @Input() todo: Todo;
  @Input() caretOptional = false;

  caretHidden = false;

  history: DropdownItem[] = [];

  constructor() {}

  ngOnChanges() {
    this.history = [];
    this.setHistory(this.history, this.note, this.todo);
    this.setCaretState();
  }

  private signedBy(note: HistoryNote): string {
    if (note.signedBy) {
      if ((<Summary>note).signedBy.name) {
        return (<Summary>note).signedBy.name;
      }

      return (<Note>note).signedBy;
    }

    return '';
  }

  private redactedBy(note: HistoryNote): string {
    const redactor = note.redactedBy;
    if (typeof redactor === 'string') {
      return redactor;
    }
    return redactor?.name || '';
  }

  private createdBy(note: HistoryNote): string {
    if (note.createdBy) {
      if ((<Summary>note).createdBy.name) {
        return (<Summary>note).createdBy.name;
      }

      return (<Note>note).createdBy;
    }

    return (<Note>note).author;
  }

  private finishedBy(todo: Todo): string {
    if (todo.finishedBy) {
      if (todo.finishedBy.name) {
        return todo.finishedBy.name;
      }
    }

    return null;
  }

  private finishedAt(todo: Todo): string {
    if (todo.finishedAt) {
      return todo.finishedAt;
    }
    return null;
  }

  private setHistory(history: SelectItem[], note: HistoryNote, todo?: Todo) {
    let currentTodo = todo;
    const isTodoEmbeddedInNote = !todo && (<Note>note).todo;
    if (isTodoEmbeddedInNote) {
      currentTodo = (<Note>note).todo;
    }

    if (note.redactedAt) {
      history.push({
        label: `Redacted ${formatDate(
          note.redactedAt,
          DateFormats['2y'],
        )} by ${this.redactedBy(note)}`,
        value: 'redactedAt',
      });
    }

    if (note.signedAt) {
      history.push({
        label: `Signed ${formatDate(
          note.signedAt,
          DateFormats['2y'],
        )} by ${this.signedBy(note)}`,
        value: 'signedAt',
      });
    } else if (currentTodo && this.finishedAt(currentTodo)) {
      history.push({
        label: `Finished ${formatDate(
          this.finishedAt(currentTodo),
          DateFormats['2y'],
        )} by ${this.finishedBy(currentTodo)}`,
        value: 'finishedAt',
      });
    }

    history.push({
      label: `Created ${formatDate(
        note.createdAt,
        DateFormats['2y'],
      )} by ${this.createdBy(note)}`,
      value: 'createdAt',
      disabled: history.length > 0,
    });
  }

  private setCaretState() {
    this.caretHidden = this.caretOptional ? this.history.length < 2 : false;
  }
}
