// @ts-strict-ignore
import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  Output,
  ViewContainerRef,
} from '@angular/core';
import { Router } from '@angular/router';

import { Profile } from '@app/core';
import { DialogService } from '@app/shared';

import { ReadyForCheckoutValidator } from '../../shared/renewal-utils';
import { Renewal } from '../../shared/renewals.type';
import { RenewalCheckoutDialogComponent } from '../renewal-checkout-dialog/renewal-checkout-dialog.component';

@Component({
  selector: 'omg-ready-for-checkout-action-bar',
  templateUrl: './ready-for-checkout-action-bar.component.html',
  styleUrls: ['./ready-for-checkout-action-bar.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ReadyForCheckoutActionBarComponent {
  @Input() checkoutPrevented: boolean;
  @Input() renewals: Renewal[];
  @Input() acceptsDigitalCommunications: boolean;
  @Input() cartId: number;
  @Input() isProvider: boolean;
  @Input() profile: Profile;
  @Input() loading: boolean;
  @Input() incompleteDemographics: boolean;
  @Input() isListView: boolean;

  @Output() checkoutAttempted = new EventEmitter<boolean>();

  constructor(
    private dialogService: DialogService,
    private router: Router,
    private validator: ReadyForCheckoutValidator,
    private viewContainerRef: ViewContainerRef,
  ) {}

  openCheckoutModal() {
    this.checkoutAttempted.emit(true);
    if (this.checkoutPrevented) {
      return;
    }

    this.dialogService.open(RenewalCheckoutDialogComponent, {
      viewContainerRef: this.viewContainerRef,
      autoFocus: true,
      disableClose: true,
      data: {
        cartId: this.cartId,
        renewals: this.renewals,
        acceptsDigitalCommunications: this.acceptsDigitalCommunications,
      },
    });
    this.router.navigateByUrl(
      `patients/${this.renewals[0].patientId}/chart/renewals/list`,
    );
  }

  get checkoutDisabled() {
    return (
      !this.isProvider ||
      !this.allRenewalsValid ||
      this.loading ||
      this.incompleteDemographics
    );
  }

  private get allRenewalsValid() {
    return this.renewals.every(renewal =>
      this.validator.validate(renewal, this.profile, this.isProvider),
    );
  }
}
