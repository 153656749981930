// @ts-strict-ignore
import { Injectable } from '@angular/core';

import {
  ProvideTodoMessageReassignmentFeedbackMutationService,
  SaveNewTodoMessageReassignmentAssigneeMutationService,
} from '@app/modules/messaging/shared/todo-message-reassignment-mutations.onelife.generated';
@Injectable()
export class TodoReassignmentFeedbackService {
  constructor(
    private provideTodoMessageReassignmentFeedbackMutationService: ProvideTodoMessageReassignmentFeedbackMutationService,
    private saveNewTodoMessageReassignmentAssigneeMutationService: SaveNewTodoMessageReassignmentAssigneeMutationService,
  ) {}

  saveFeedback(id: string, feedbackCategory: string, feedbackText: string) {
    this.provideTodoMessageReassignmentFeedbackMutationService
      .mutate({ id, feedbackCategory, feedbackText })
      .subscribe();
  }

  saveAssignee(id: string, assigneeId: string, assigneeType: string) {
    this.saveNewTodoMessageReassignmentAssigneeMutationService
      .mutate({ id, assigneeId, assigneeType })
      .subscribe();
  }
}
