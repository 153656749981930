// @ts-strict-ignore
import { Action } from '@ngrx/store';

import { FsaAction } from '@app/core';

import {
  HealthGoal,
  HealthGoalErrors,
  HealthGoalForm,
  HealthGoalWithScreenings,
  HealthMaintenanceError,
} from '../shared/health-maintenance.type';

export enum HealthGoalActionTypes {
  LoadHealthGoals = '[HealthGoal] Load HealthGoals',
  LoadHealthGoalsSuccess = '[HealthGoal] Load HealthGoals Success',
  LoadHealthGoalsError = '[HealthGoal] Load HealthGoals Error',
  LoadHealthGoal = '[HealthGoal] Load HealthGoal',
  LoadHealthGoalSuccess = '[HealthGoal] Load HealthGoal Success',
  AddHealthGoal = '[HealthGoal] Add HealthGoal',
  AddHealthGoalSuccess = '[HealthGoal] Add HealthGoal Success',
  AddHealthGoalError = '[HealthGoal] Add HealthGoal Error',
  UpdateHealthGoal = '[HealthGoal] Update HealthGoal',
  UpdateHealthGoalSuccess = '[HealthGoal] Update HealthGoal Success',
  UpdateHealthGoalError = '[HealthGoal] Update HealthGoal Error',
  DeleteHealthGoal = '[HealthGoal] Delete HealthGoal',
  DeleteHealthGoalSuccess = '[HealthGoal] Delete HealthGoal Success',
  DeleteHealthGoalError = '[HealthGoal] Delete HealthGoal Error',
  InitNewHealthGoal = '[HealthGoal] Init New HealthGoal',
  ClearHealthGoals = '[HealthGoal] Clear HealthGoals',
  InitPartialHealthGoal = '[HealthGoal] Init Partial HealthGoal',
  SavePartialHealthGoal = '[HealthGoal] Save Partial HealthGoal',
  SavePartialHealthGoalSuccess = '[HealthGoal] Save Partial HealthGoal Success',
  DiscardPartialHealthGoal = '[Health Goal] Discard Partial HealthGoal',
  ToggleHealthGoalExpansion = '[HealthGoal] Toggle HealthGoal Expansion',
}

type PatientId = number;
type HealthGoalId = number;

interface AddHealthGoalPayload {
  patientId: number;
  healthGoal: HealthGoalForm;
}

export class LoadHealthGoals implements FsaAction<PatientId> {
  readonly type = HealthGoalActionTypes.LoadHealthGoals;

  constructor(public payload: PatientId) {}
}

export class LoadHealthGoalsSuccess
  implements FsaAction<HealthGoalWithScreenings[]> {
  readonly type = HealthGoalActionTypes.LoadHealthGoalsSuccess;
  error = false;

  constructor(public payload: HealthGoalWithScreenings[]) {}
}

export class LoadHealthGoalsError implements FsaAction<HealthMaintenanceError> {
  readonly type = HealthGoalActionTypes.LoadHealthGoalsError;
  error = true;

  constructor(public payload: HealthMaintenanceError) {}
}

export class AddHealthGoal implements FsaAction<AddHealthGoalPayload> {
  readonly type = HealthGoalActionTypes.AddHealthGoal;

  constructor(public payload: AddHealthGoalPayload) {}
}

export class AddHealthGoalSuccess
  implements FsaAction<HealthGoalWithScreenings> {
  readonly type = HealthGoalActionTypes.AddHealthGoalSuccess;
  error = false;

  constructor(public payload: HealthGoalWithScreenings) {}
}

export class AddHealthGoalError implements FsaAction<HealthGoalErrors> {
  readonly type = HealthGoalActionTypes.AddHealthGoalError;
  error = true;

  constructor(public payload: HealthGoalErrors, public meta: { id: number }) {}
}

export class UpdateHealthGoal implements FsaAction<HealthGoalForm> {
  readonly type = HealthGoalActionTypes.UpdateHealthGoal;

  constructor(public payload: HealthGoalForm) {}
}

export class UpdateHealthGoalSuccess implements FsaAction<HealthGoal> {
  readonly type = HealthGoalActionTypes.UpdateHealthGoalSuccess;
  error = false;

  constructor(public payload: HealthGoal) {}
}

export class UpdateHealthGoalError implements FsaAction<HealthGoalErrors> {
  readonly type = HealthGoalActionTypes.UpdateHealthGoalError;
  error = true;

  constructor(public payload: HealthGoalErrors, public meta: { id: number }) {}
}

export class DeleteHealthGoal implements FsaAction<HealthGoalId> {
  readonly type = HealthGoalActionTypes.DeleteHealthGoal;

  constructor(public payload: HealthGoalId) {}
}

export class DeleteHealthGoalSuccess implements FsaAction<HealthGoalId> {
  readonly type = HealthGoalActionTypes.DeleteHealthGoalSuccess;
  error = false;

  constructor(public payload: HealthGoalId) {}
}

export class DeleteHealthGoalError
  implements FsaAction<HealthMaintenanceError> {
  readonly type = HealthGoalActionTypes.DeleteHealthGoalError;
  error = true;

  constructor(
    public payload: HealthMaintenanceError,
    public meta: { id: HealthGoalId },
  ) {}
}

export class ClearHealthGoals implements Action {
  readonly type = HealthGoalActionTypes.ClearHealthGoals;
}

export class InitNewHealthGoal implements Action {
  readonly type = HealthGoalActionTypes.InitNewHealthGoal;
}

export class LoadHealthGoal implements Action {
  readonly type = HealthGoalActionTypes.LoadHealthGoal;

  constructor(public readonly id: HealthGoalId) {}
}

export class LoadHealthGoalSuccess implements Action {
  readonly type = HealthGoalActionTypes.LoadHealthGoalSuccess;

  constructor(public readonly goal: HealthGoalWithScreenings) {}
}

export class InitPartialHealthGoal implements Action {
  readonly type = HealthGoalActionTypes.InitPartialHealthGoal;
}

export class SavePartialHealthGoal implements Action {
  readonly type = HealthGoalActionTypes.SavePartialHealthGoal;

  constructor(public readonly goal: HealthGoalForm) {}
}

export class SavePartialHealthGoalSuccess implements Action {
  readonly type = HealthGoalActionTypes.SavePartialHealthGoalSuccess;

  constructor(public readonly goal: HealthGoalWithScreenings) {}
}

export class DiscardPartialHealthGoal implements Action {
  readonly type = HealthGoalActionTypes.DiscardPartialHealthGoal;
}

export class ToggleHealthGoalExpansion implements Action {
  readonly type = HealthGoalActionTypes.ToggleHealthGoalExpansion;

  constructor(
    public readonly healthGoalId: number,
    public readonly expanded: boolean,
  ) {}
}

export type HealthGoalActions =
  | LoadHealthGoals
  | LoadHealthGoalsSuccess
  | LoadHealthGoalsError
  | LoadHealthGoal
  | LoadHealthGoalSuccess
  | AddHealthGoal
  | AddHealthGoalSuccess
  | AddHealthGoalError
  | UpdateHealthGoal
  | UpdateHealthGoalSuccess
  | UpdateHealthGoalError
  | DeleteHealthGoal
  | DeleteHealthGoalSuccess
  | DeleteHealthGoalError
  | ClearHealthGoals
  | InitNewHealthGoal
  | InitPartialHealthGoal
  | SavePartialHealthGoal
  | SavePartialHealthGoalSuccess
  | DiscardPartialHealthGoal
  | ToggleHealthGoalExpansion;
