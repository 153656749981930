import { Component, Input, OnInit } from '@angular/core';
import { first, map, Observable, take } from 'rxjs';

import { AnalyticsService } from '@app/core/analytics';
import { AnalyticsEvent } from '@app/core/analytics/analytics.type';
import { DefaultAnalyticsProps } from '@app/core/analytics/default-analytics-props';
import { FeatureFlagNames } from '@app/core/feature-flag/shared/feature-flag.type';
import { LaunchDarklyService } from '@app/core/launch-darkly/launchdarkly.service';

import {
  AddDocumentSummaryFeedbackMutationInput,
  DocumentSummary,
  DocumentSummaryClientAppName,
  DocumentSummaryDataFieldName,
  DocumentSummaryFeedbackFieldInput,
} from '../../../../../graphql/onelife.type';
import {
  AddDocumentFeedbackMutationService,
  GetDocumentSummaryQueryService,
} from '../../shared/document-summary.onelife.generated';
import { UnavailableSummary } from '../../shared/unavailable-summary';

@Component({
  selector: 'omg-document-summary',
  templateUrl: './document-summary.component.html',
  styleUrls: ['./document-summary.component.scss'],
})
export class DocumentSummaryComponent implements OnInit {
  @Input() documentId: number;
  documentSummarizationVisible: boolean;
  documentSummaryFeedbackVisible: boolean;
  summary$: Observable<DocumentSummary>;

  constructor(
    private launchDarklyService: LaunchDarklyService,
    private documentSummaryService: GetDocumentSummaryQueryService,
    private documentFeedbackService: AddDocumentFeedbackMutationService,
    private analytics: AnalyticsService,
  ) {}

  ngOnInit(): void {
    this.documentSummarizationVisible = this.launchDarklyService.variation(
      FeatureFlagNames.chartDocumentSummarizationVisible,
      false,
    );
    this.documentSummaryFeedbackVisible = this.launchDarklyService.variation(
      FeatureFlagNames.chartDocumentSummarizationFeedback,
      false,
    );
    this.initSummary();
  }

  initSummary(): void {
    this.summary$ = this.documentSummaryService
      .fetch({ documentId: this.documentId.toString() })
      .pipe(map(result => result.data.document.summary || UnavailableSummary));
  }

  onFeedbackSubmitted(feedback: DocumentSummaryFeedbackFieldInput[]) {
    this.summary$.pipe(first()).subscribe(summary => {
      this.documentFeedbackService
        .mutate({
          input: {
            feedback: {
              documentSummaryId: summary.id,
              metadata: {
                url: window.location.href,
                clientAppName: DocumentSummaryClientAppName.PatientChart,
              },
              displayedSummaryDataFields: [
                DocumentSummaryDataFieldName.DetailedSummary,
              ],
              feedbackFields: feedback,
            },
          },
        })
        .subscribe();
    });
  }

  onViewerCollapseToggle(expanded: boolean): void {
    if (expanded !== null) {
      this.summary$.pipe(take(1)).subscribe(summary => {
        const event = expanded
          ? AnalyticsEvent.DocumentSummaryExpanded
          : AnalyticsEvent.DocumentSummaryCollapsed;
        this.analytics.track(event, {
          ...DefaultAnalyticsProps,
          component: 'Document Summary',
          subcomponent: 'Document Summary Viewer',
          documentId: this.documentId,
          summaryId: summary.id,
        });
      });
    }
  }
}
