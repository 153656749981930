import { animate, style, transition, trigger } from '@angular/animations';
import { Component, EventEmitter, Output, ViewChild } from '@angular/core';
import {
  UntypedFormBuilder,
  UntypedFormControl,
  UntypedFormGroup,
} from '@angular/forms';

import { CollapsibleBannerComponent } from '@app/shared/components/collapsible-banner/collapsible-banner.component';

import { DocumentSummaryFeedbackFieldInput } from '../../../../../graphql/onelife.type';

@Component({
  selector: 'omg-document-summary-feedback',
  templateUrl: './document-summary-feedback.component.html',
  styleUrls: ['./document-summary-feedback.component.scss'],
  animations: [
    trigger('thanksAnimation', [
      transition(':leave', [
        style({ height: 300, opacity: 1 }),
        animate('0.5s ease-in', style({ height: 0, opacity: 0 })),
      ]),
    ]),
  ],
})
export class DocumentSummaryFeedbackComponent {
  feedbackForm: UntypedFormGroup;
  bannerThanksTextVisible: boolean = false;
  reviewTextVisible: boolean = true;

  @Output() feedbackSubmitted: EventEmitter<
    DocumentSummaryFeedbackFieldInput[]
  > = new EventEmitter<DocumentSummaryFeedbackFieldInput[]>();

  @ViewChild(CollapsibleBannerComponent) banner: CollapsibleBannerComponent;

  constructor(private formBuilder: UntypedFormBuilder) {
    const feedbackResponse = new UntypedFormControl();
    const freetextFeedback = new UntypedFormControl();
    this.feedbackForm = this.formBuilder.group({
      feedbackResponse,
      freetextFeedback,
    });
  }

  /***
   * Transform the presented and selected values into the input types
   * expected by our backend. The values are extracted from the form components.
   */
  generateFeedbackInputs(): DocumentSummaryFeedbackFieldInput[] {
    const selectedValue: string | null =
      this.feedbackForm.get('feedbackResponse')?.value;
    const freetextValue: string | null =
      this.feedbackForm.get('freetextFeedback')?.value;
    const feedback: DocumentSummaryFeedbackFieldInput[] = [];
    if (selectedValue) {
      const selectedOption = this.feedbackOptions.find(
        f => f.value === selectedValue,
      );
      selectedOption &&
        feedback.push({
          name: 'Impression',
          options: this.feedbackOptions,
          submitted: [selectedOption],
        });
    }
    if (freetextValue) {
      feedback.push({
        name: 'Tell us Why',
        options: [],
        submitted: [
          {
            label: 'Tell us Why',
            value: freetextValue,
          },
        ],
      });
    }
    return feedback;
  }

  onSubmit() {
    const feedback = this.generateFeedbackInputs();
    this.feedbackSubmitted.emit(feedback);
    this.triggerSubmissionAnimation();
  }

  /***
   * Flips the properties that cause the "Thank you" text to appear and transition out.
   */
  triggerSubmissionAnimation(): void {
    this.bannerThanksTextVisible = true;
    this.reviewTextVisible = false;
    this.banner.collapse();
    setTimeout(() => {
      this.bannerThanksTextVisible = false;
      setTimeout(() => {
        this.reviewTextVisible = true;
      }, 550);
    }, 2000);
  }

  onCancel() {
    this.banner.collapse();
  }

  onExpandChange(expanded: boolean) {
    if (!expanded) {
      this.resetFormValues();
    }
  }

  resetFormValues() {
    this.feedbackForm.reset({
      feedbackResponse: null,
    });
  }

  feedbackOptions = [
    {
      label: 'The summary is accurate and I found it useful',
      value: '0',
    },
    {
      label: 'The summary is accurate, but I did not find it useful',
      value: '1',
    },
    {
      label: 'The summary seems inaccurate',
      value: '2',
    },
    {
      label: 'Summary is not available',
      value: '3',
    },
  ];
}
