// @ts-strict-ignore
import { Component, OnInit } from '@angular/core';
import { Observable } from 'rxjs';
import { filter, switchMap } from 'rxjs/operators';

import { DialogRef } from '@app/shared/components/dialog';

import { lookupDestinationText } from '../../shared/lookup-pharmacy-destination-text-util';
import {
  PrescriptionHistory,
  PrescriptionHistoryTabs,
} from '../../shared/prescription-history.type';
import { PrescriptionHistoryActions } from '../../store/prescription-history.actions';
import { PrescriptionHistorySelectors } from '../../store/prescription-history.selectors';

@Component({
  selector: 'omg-full-prescription-history',
  templateUrl: './full-prescription-history.component.html',
  styleUrls: ['./full-prescription-history.component.scss'],
})
export class FullPrescriptionHistoryComponent implements OnInit {
  prescriptionHistory$: Observable<PrescriptionHistory[]>;
  currentHistoryTab = PrescriptionHistoryTabs.history.id;
  prescriptionHistoryTabs = PrescriptionHistoryTabs;
  rxCartId?: number;

  constructor(
    private dialogRef: DialogRef<FullPrescriptionHistoryComponent>,
    private prescriptionHistoryActions: PrescriptionHistoryActions,
    private prescriptionHistorySelectors: PrescriptionHistorySelectors,
  ) {}

  ngOnInit() {
    this.prescriptionHistoryActions.load();
    this.prescriptionHistory$ = this.prescriptionHistorySelectors.loading.pipe(
      filter(loading => !loading),
      switchMap(() => this.prescriptionHistorySelectors.entities),
    );
  }

  getDestinationText(rx: PrescriptionHistory): string {
    return lookupDestinationText(rx);
  }

  trackByFn = (index: number, history: PrescriptionHistory) =>
    history.id ? history.id : index;

  onClose() {
    this.dialogRef.close();
  }
}
