// @ts-strict-ignore
import { createEntityAdapter, EntityState } from '@ngrx/entity';

import {
  createStoreMetadataAdapter,
  EntityMetadataMap,
  getEntityMetadataInitialState,
  resetEntityMetadata,
  StoreMetadataMap,
  updateEntityMetadata,
} from '@app/utils/store';

import { PendingNewRx } from '../shared/rx-cart.type';
import * as fromPendingNewRx from './pending-new-rx.actions';

export interface PendingRxCartItemsState extends EntityState<PendingNewRx> {
  loading: boolean;
  error: any;
  pendingOperations: StoreMetadataMap;
  metadata: EntityMetadataMap<PendingNewRx>;
}

export function selectPendingNewRxId(pendingNewRx: PendingNewRx): number {
  return pendingNewRx.id;
}

export const adapter = createEntityAdapter<PendingNewRx>({
  selectId: selectPendingNewRxId,
  sortComparer: false,
});

export const metadataAdapter = createStoreMetadataAdapter('pendingOperations');

export const initialState: PendingRxCartItemsState = adapter.getInitialState({
  loading: false,
  error: null,
  pendingOperations: {},
  ...getEntityMetadataInitialState({}),
});

export function reducer(
  state = initialState,
  action: fromPendingNewRx.PendingNewRxAction,
): PendingRxCartItemsState {
  switch (action.type) {
    case fromPendingNewRx.ADD_EXISTING_PENDING_RX_CART_ITEMS: {
      return adapter.setAll(action.payload, {
        ...state,
        loading: false,
        error: null,
      });
    }

    case fromPendingNewRx.CREATE_PENDING_RX_CART_ITEM: {
      return {
        ...state,
        loading: true,
        ...metadataAdapter.upsertOne(
          action.meta.correlationId,
          { pending: true },
          state,
        ),
      };
    }

    case fromPendingNewRx.CREATE_PENDING_RX_CART_ITEM_SUCCESS: {
      return adapter.addOne(action.payload, {
        ...state,
        loading: false,
        error: null,
        ...metadataAdapter.upsertOne(
          action.meta.correlationId,
          { pending: false },
          state,
        ),
      });
    }

    case fromPendingNewRx.CREATE_PENDING_RX_CART_ITEM_ERROR: {
      return {
        ...state,
        loading: false,
        error: action.payload,
        ...metadataAdapter.upsertOne(
          action.meta.correlationId,
          { pending: false, error: action.payload },
          state,
        ),
      };
    }

    case fromPendingNewRx.REMOVE_PENDING_RX_CART_ITEM: {
      return { ...state, loading: true };
    }

    case fromPendingNewRx.REMOVE_PENDING_RX_CART_ITEM_SUCCESS: {
      return adapter.removeOne(action.payload.cartItemId, {
        ...state,
        loading: false,
        error: null,
      });
    }

    case fromPendingNewRx.REMOVE_PENDING_RX_CART_ITEM_ERROR: {
      return { ...state, loading: false, error: action.payload };
    }

    case fromPendingNewRx.UPDATE_PENDING_RX_CART_ITEM: {
      return {
        ...state,
        loading: true,
        ...updateEntityMetadata(action.payload.id, { pending: true }, state),
      };
    }

    case fromPendingNewRx.UPDATE_PENDING_RX_CART_ITEM_SUCCESS: {
      return adapter.updateOne(action.payload, {
        ...state,
        loading: false,
        error: null,
        ...resetEntityMetadata(action.payload.id, state),
      });
    }

    case fromPendingNewRx.UPDATE_PENDING_RX_CART_ITEM_ERROR: {
      return {
        ...state,
        loading: false,
        error: action.payload,
        ...updateEntityMetadata(
          action.meta.id,
          { pending: false, error: action.payload },
          state,
        ),
      };
    }

    case fromPendingNewRx.MARK_HANDWRITTEN_CONFIRMED: {
      const update = {
        id: action.payload,
        changes: { handwrittenConfirmed: true },
      };

      return adapter.updateOne(update, {
        ...state,
        loading: false,
        error: null,
      });
    }

    case fromPendingNewRx.MARK_HANDWRITTEN_UNCONFIRMED: {
      const update = {
        id: action.payload,
        changes: { handwrittenConfirmed: false },
      };

      return adapter.updateOne(update, {
        ...state,
        loading: false,
        error: null,
      });
    }

    default:
      return state;
  }
}
