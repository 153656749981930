import { DocumentSummary } from '../../../../graphql/onelife.type';

export const UnavailableSummary: DocumentSummary = {
  id: '0',
  data: {},
  content: {
    detailedSummary: '',
  },
  feedbacks: [],
  createdAt: null,
  updatedAt: null,
};
