import { environment } from '@environments/environment';
import * as Sentry from '@sentry/angular-ivy';
import { HttpClient } from '@sentry/integrations';

import { scrubURL } from './error-utils';

export const SENTRY_UUID_LOCALSTORAGE_KEY = 'sentry-machine-uuid';

export const SentryErrorHandler = Sentry.createErrorHandler({
  showDialog: false,
});

export const initializeSentry = () => {
  if (environment.sentry.enabled) {
    Sentry.init({
      environment: environment.sentry.environment,
      dsn: environment.sentry.dsn,
      release: environment.codeVersion,
      ignoreErrors: ['Object captured as exception'],
      integrations: [
        // Registers and configures the Tracing integration,
        // which automatically instruments your application to monitor its
        // performance, including custom Angular routing instrumentation
        new Sentry.BrowserTracing({
          routingInstrumentation: Sentry.routingInstrumentation,
        }),
        new HttpClient(),
      ],
      tracesSampleRate: 0,
      beforeSend: event => {
        if (event.request?.url) {
          const url = new URL(event.request.url);
          const scrubbed = scrubURL(url);
          event.request.url = scrubbed;
          if (event.tags) {
            event.tags.transaction = scrubbed;
          }
        }
        return event;
      },
    });
    setSentryUser();
  }
};

/**
 * Generates a UUID that is stored in localStorage and used as a proxy for an
 * individual provider. If an existing value is found, uses that instead.
 *
 * This UUID is used in place of a proper user identifier so that we can determine
 * the blast radius of sentries without risking provider info being pushed outside of
 * our systems.
 *
 * @returns the user data supplied to sentry, for introspection
 */
export const setSentryUser: () => Sentry.User = () => {
  let machineUUID = localStorage.getItem(SENTRY_UUID_LOCALSTORAGE_KEY);
  if (!machineUUID) {
    machineUUID = crypto.randomUUID();
    localStorage.setItem(SENTRY_UUID_LOCALSTORAGE_KEY, machineUUID);
  }

  const sentryUser = {
    id: machineUUID,
  };
  Sentry.setUser(sentryUser);

  return sentryUser;
};
