import { formatDosage } from '@app/modules/shared-rx/utils';

import { MedicationRegimen, MedicationRegimenForm } from './medications.type';

export const mapMedicationRegimenToForm = (
  data?: MedicationRegimen,
): MedicationRegimenForm => {
  const template = data?.medicationPrescriptionTemplate;
  const templateItem = template && template.medicationPrescriptionItemTemplate;
  const frequencyInterval =
    templateItem && templateItem.medicationFrequencyInterval;
  const prn = template && template.medicationPrn;

  return {
    medicationRouteId: data?.medicationRouteId,
    medicationRegimenId: data?.id,
    isCustomRegimen: data?.isCustom,
    dispensableId: templateItem && templateItem.medicationDispensableId,
    prescriptionTemplateId: template && template.id,
    prescriptionTemplateItemId: templateItem && templateItem.id,
    dose: template && (formatDosage(template) as string),
    durationDays: templateItem && templateItem.durationDays,
    frequencyIntervalId: frequencyInterval && frequencyInterval.id,
    instructionsText: templateItem && templateItem.instructionsText,
    useInstructionsText: !!(templateItem && templateItem.instructionsText),
    usePrn: !!prn,
    prnId: prn && prn.id,
    prnDescription: prn && prn.desc,
  };
};
