import { HttpErrorResponse } from '@angular/common/http';
// @ts-strict-ignore
import { Injectable } from '@angular/core';
import { Observable, throwError } from 'rxjs';
import { catchError, map, switchMap } from 'rxjs/operators';

import { ApiService, patientRoute, WrappedError } from '@app/core';

import { mapMedicationRegimenToMedication } from './medications-api-mappers';
import { MedicationsApiService } from './medications-api.service';
import { MedicationRegimenForm } from './medications.type';
import {
  mapFormToSavePatientMedicationPayload,
  mapFormToSavePatientMedicationRegimenPayload,
  mapMedsRecNotificationToEntity,
  mapMedsRecResponseToMedsRecEntity,
  mapPatienMedicationsResponseToEntities,
  mapPatientMedicationRegimensToEntities,
  mapPatientMedicationRegimenToEntity,
  mapPatientMedicationResponseToEntity,
  mapPrescriptionHistoryResponseToEntities,
} from './patient-medications-api-mappers';
import {
  GetMedsRecNotificationResponse,
  MedicationsReconciliationResponse,
  PatientMedicationPrescriptionHistoryItemResponse,
  PatientMedicationRegimenResponse,
  PatientMedicationResponse,
  PutMedicationReconciliationRequest,
} from './patient-medications-api.type';
import {
  MedicationsReconciliationEvent,
  MedsRecNotification,
  PatientMedication,
  PatientMedicationForm,
  PatientMedicationId,
  PatientMedicationRegimen,
} from './patient-medications.type';

export const patientMedicationsAdminRoute = (subRoute: string = ''): string => {
  const patientMedicationsPath = '/v2/admin/patient_medications';
  return `${patientMedicationsPath}${subRoute}`;
};

export const patientMedicationsRoute = (patientId: number): string => {
  const patientMedicationsPath = '/patient_medications';
  return patientRoute(patientId, patientMedicationsPath);
};

export const patientMedicationByIdRoute = (medicationId: number): string =>
  patientMedicationsAdminRoute(`/${medicationId}`);

export const patientMedicationRegimensRoute = (
  subRoute: string = '',
): string => {
  const patientMedicationRegimensPath = '/v2/admin/patient_medication_regimens';
  return `${patientMedicationRegimensPath}${subRoute}`;
};

export const patientMedicationRegimensByIdRoute = (
  medicationId: number,
): string =>
  `${patientMedicationByIdRoute(medicationId)}/patient_medication_regimens`;

export const patientMedicationsRxHistoryRoute = (
  medicationId: number,
): string => `${patientMedicationByIdRoute(medicationId)}/rx_history`;

@Injectable({
  providedIn: 'root',
})
export class PatientMedicationsApiService {
  constructor(
    private api: ApiService,
    private medicationService: MedicationsApiService,
  ) {}

  getAll(patientId: number): Observable<PatientMedication[]> {
    return this.api
      .get<PatientMedicationResponse[]>(patientMedicationsRoute(patientId))
      .pipe(map(mapPatienMedicationsResponseToEntities));
  }

  getPrescriptionHistory(medicationId: number) {
    return this.api
      .get<PatientMedicationPrescriptionHistoryItemResponse[]>(
        patientMedicationsRxHistoryRoute(medicationId),
      )
      .pipe(
        map(response => mapPrescriptionHistoryResponseToEntities(response)),
      );
  }

  getPatientMedicationRegimenHistory(medicationId: number) {
    return this.api
      .get<PatientMedicationRegimenResponse[]>(
        patientMedicationRegimensByIdRoute(medicationId),
      )
      .pipe(map(response => mapPatientMedicationRegimensToEntities(response)));
  }

  add(data: PatientMedicationForm): Observable<PatientMedication> {
    return this.api
      .save<PatientMedicationResponse>(
        patientMedicationsAdminRoute('/create_with_associations'),
        mapFormToSavePatientMedicationPayload(data),
      )
      .pipe(map(mapPatientMedicationResponseToEntity));
  }

  update(data: PatientMedicationForm): Observable<PatientMedicationRegimen> {
    // If not custom then only save the patient_medication_regimen
    if (!data.isCustomRegimen) {
      return this.savePatientMedicationRegimen(data);
    }

    // Save medication_regimen first, then patient_medication_regimen
    return this.medicationService
      .saveMedicationRegimen(data as MedicationRegimenForm)
      .pipe(
        switchMap(result => {
          const values = mapMedicationRegimenToMedication(result);

          return this.savePatientMedicationRegimen({
            ...data,
            ...values,
          });
        }),
      );
  }

  delete(medicationId: number): Observable<PatientMedication> {
    return this.api
      .update<PatientMedicationResponse>(
        patientMedicationByIdRoute(medicationId),
        {
          id: medicationId,
          is_deleted: true,
          is_active: false,
        },
      )
      .pipe(map(mapPatientMedicationResponseToEntity));
  }

  activate(
    medicationId: PatientMedicationId,
    isActive: boolean = false,
  ): Observable<PatientMedication> {
    return this.api
      .update<PatientMedicationResponse>(
        patientMedicationByIdRoute(medicationId),
        {
          id: medicationId,
          is_active: isActive,
        },
      )
      .pipe(map(mapPatientMedicationResponseToEntity));
  }

  getLatestMedsRec(
    patientId: number,
  ): Observable<MedicationsReconciliationEvent> {
    const route = patientRoute(
      patientId,
      `/patient_medications/reconciliation/latest`,
    );

    return this.api
      .get<MedicationsReconciliationResponse>(route)
      .pipe(
        map(mapMedsRecResponseToMedsRecEntity),
        catchError(this.handleError),
      );
  }

  saveMedsRec(
    patientId: number,
    reviewedAtTimestamp: string,
  ): Observable<MedicationsReconciliationEvent> {
    const route = patientRoute(
      patientId,
      `/patient_medications/reconciliation`,
    );

    const request: PutMedicationReconciliationRequest = {
      reviewed_at: reviewedAtTimestamp,
    };

    return this.api
      .save<MedicationsReconciliationResponse>(route, request)
      .pipe(
        catchError(this.handleError),
        map(mapMedsRecResponseToMedsRecEntity),
      );
  }

  getMedsRecNotification(patientId: number): Observable<MedsRecNotification> {
    const route = patientRoute(
      patientId,
      `/patient_medications/reconciliation/notification`,
    );

    return this.api
      .get<GetMedsRecNotificationResponse>(route)
      .pipe(catchError(this.handleError), map(mapMedsRecNotificationToEntity));
  }

  private savePatientMedicationRegimen(
    data: PatientMedicationForm,
  ): Observable<PatientMedicationRegimen> {
    return this.api
      .save<PatientMedicationRegimenResponse>(
        patientMedicationRegimensRoute(),
        mapFormToSavePatientMedicationRegimenPayload(data),
      )
      .pipe(map(mapPatientMedicationRegimenToEntity));
  }

  private handleError(error: HttpErrorResponse) {
    switch (error.status) {
      case 404:
        return throwError(
          () =>
            new ResourceNotFoundError('API Failure: Resource not found', error),
        );
      case 403:
        return throwError(
          () =>
            new UnAuthorizedError(
              'API Failure: User cannot perform action',
              error,
            ),
        );
      case 500:
        return throwError(
          () => new ServerError('API Failure: Server Error', error),
        );
      default:
        return throwError(() => error);
    }
  }
}

export class ResourceNotFoundError extends WrappedError {}
export class UnAuthorizedError extends WrappedError {}
export class ServerError extends WrappedError {}
