// @ts-strict-ignore
import {
  Component,
  DestroyRef,
  inject,
  Input,
  OnInit,
  ViewChild,
} from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { UntypedFormControl } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { take } from 'rxjs/operators';

import { ActionBarComponent } from '@app/shared/components/action-bar/action-bar.component';

import {
  DenialReason,
  Renewal,
  RenewalCartState,
} from '../../shared/renewals.type';
import { RenewalSelectors } from '../../store';

@Component({
  selector: 'omg-renewal-item-action-bar',
  templateUrl: './renewal-item-action-bar.component.html',
  styleUrls: ['./renewal-item-action-bar.component.css'],
})
export class RenewalItemActionBarComponent implements OnInit {
  @ViewChild(ActionBarComponent, { static: true })
  actionBar: ActionBarComponent;
  @Input() renewal: Renewal;
  @Input() disabledApproval: boolean;
  @Input() disabledDenial: boolean;
  @Input() cartState: UntypedFormControl;
  @Input() denialReason: UntypedFormControl;

  currentRenewalState: RenewalCartState;
  renewalRouterLink: string;

  private destroyRef = inject(DestroyRef);

  constructor(
    private renewalSelectors: RenewalSelectors,
    private router: Router,
    private route: ActivatedRoute,
  ) {}

  ngOnInit() {
    this.renewalSelectors.pendingRenewalIds
      .pipe(take(1))
      .subscribe((ids: number[]) => {
        this.renewalRouterLink = this.nextRenewalRouterLink(
          this.renewal.id,
          ids,
        );
        this.setupRenewalStateListener();
      });
  }

  get showNextRequest() {
    return !!this.renewalRouterLink && this.renewalRouterLink !== '../';
  }

  showConfirmDrawer() {
    this.actionBar.showConfirmDrawer();
  }

  approve() {
    this.cartState.patchValue(RenewalCartState.approved);
  }

  deny(denialReason: DenialReason) {
    this.cartState.patchValue(RenewalCartState.denied, { emitEvent: false });
    this.denialReason.patchValue(denialReason);
  }

  cancel() {
    this.actionBar.hideConfirmDrawer();
  }

  private nextRenewalRouterLink(id: number, ids: number[]) {
    const currentIdx = ids.indexOf(id);
    if (currentIdx === ids.length - 1) {
      return '../';
    }
    return '../' + ids[currentIdx + 1];
  }

  private setupRenewalStateListener() {
    this.renewalSelectors
      .renewalCartState(this.renewal.id)
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe(state => {
        this.nagivateForStateChange(state);
        this.currentRenewalState = state;
      });
  }

  private nagivateForStateChange(state) {
    const renewalWasApprovedOrDenied =
      this.currentRenewalState === RenewalCartState.pending &&
      [RenewalCartState.approved, RenewalCartState.denied].includes(state);

    if (renewalWasApprovedOrDenied) {
      this.nextRenewalRequest();
    }
  }

  private nextRenewalRequest() {
    this.router.navigate([this.renewalRouterLink], {
      relativeTo: this.route,
    });
  }
}
