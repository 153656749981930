import { Directive, ElementRef, HostListener, Input } from '@angular/core';

import { PopoverOptions, PopoverTriggerEvent } from './popover-config';
import { PopoverRef } from './popover-ref';
import { PopoverComponent } from './popover.component';
import { PopoverService } from './popover.service';

// eslint-disable  @angular-eslint/no-input-rename

@Directive({
  selector: '[omgPopoverTriggerFor]',
  exportAs: 'omgPopover',
})
export class PopoverTriggerDirective {
  @Input('omgPopoverTriggerFor') popover: PopoverOptions | PopoverComponent;
  @Input() triggerOn: PopoverTriggerEvent = 'click';

  private popoverRef: PopoverRef;

  get isOpen() {
    return this.popoverRef && this.popoverRef.isOpen;
  }

  constructor(
    private elementRef: ElementRef,
    private popoverService: PopoverService,
  ) {}

  toggle() {
    if (this.isOpen) {
      this.close();
    } else {
      this.open();
    }
  }

  private open() {
    if (this.isOpen || !this.popover?.enabled) {
      return;
    }

    this.popoverRef = this.popoverService.open({
      ...this.popover,
      triggerOn: this.triggerOn,
      origin: this.elementRef.nativeElement,
    });

    this.initPopover();
  }

  private close() {
    if (this.isOpen) {
      this.popoverRef.close();
    }
  }

  private initPopover() {
    if (this.popover instanceof PopoverComponent) {
      this.popover.popoverRef = this.popoverRef;
    }
  }

  @HostListener('click', ['$event']) private onClick(event: MouseEvent) {
    if (this.triggerOn === 'click') {
      this.toggle();
    }
  }

  @HostListener('mouseenter', ['$event']) onMouseEnter(event: MouseEvent) {
    if (this.triggerOn === 'hover') {
      this.open();
    }
  }

  async delay(ms: number) {
    await new Promise(resolve => setTimeout(resolve, ms));
  }

  @HostListener('mouseleave', ['$event']) onMouseLeave(event: MouseEvent) {
    if (this.triggerOn === 'hover') {
      this.delay(100).then(() => {
        if (this.popoverRef && !this.popoverRef.hoveredOver) {
          this.close();
        }
      });
    }
  }
}
